(<template>
  <div>
    <auth-tabs :links="authLinks" />
  </div>
</template>)

<script>
  import AuthFormTabs from '@/components/auth_components/AuthFormTabs';

  export default {
    components: {
      'auth-tabs': AuthFormTabs
    },
    computed: {
      authLinks() {
        return [{
          name: this.$gettext('LOGIN WITH EMAIL'),
          path: this.$makeRoute({name: 'SignIn'})
        }, {
          name: this.$gettext('LOGIN WITH PHONE'),
          path: this.$makeRoute({name: 'SignIn', query: {option: 'phone'}})
        }, {
          name: this.$gettext('CREATE AN ACCOUNT'),
          path: this.$makeRoute({name: 'SignUp'})
        }];
      }
    }
  };
</script>
