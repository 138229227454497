(<template>
  <div :class="{'in-progress': progressActive}"
       class="shared-form is-progress-bar">
    <div v-if="punchoutProgressActive"
         class="shared-form-punchout-login__progress">
      <h2>{{ $gettext('Authenticating order') }}</h2>
    </div>
    <form v-else
          class="shared-form__form"
          @submit.stop.prevent="submitForm">
      <div v-if="isPhoneOption"
           class="shared-form__inputs-wrapper">
        <sk-select :type="'countryCodes'"
                   :description="phoneCodeText"
                   :default-value="phoneCodeText"
                   :preselected-value="phoneCode"
                   :mob-numeric-keyboard="true"
                   class="shared-form__code-input"
                   @enterpressed="submitForm"
                   @csvaluechanged="setPhoneCode" />
        <sk-input :description="phoneNumberText"
                  :preselected-value="phoneNumber"
                  :margin-bottom="true"
                  :placeholder="phoneNumberText"
                  :validation-name="phoneNumberErrorsName"
                  :autofocus="true"
                  :type="'tel'"
                  :reg-exp="/[^0-9]/g"
                  :mob-numeric-keyboard="true"
                  class="shared-form__number-input"
                  @enterpressed="submitForm"
                  @fieldvaluechanged="setPhoneNumber" />
      </div>
      <sk-input v-else
                :id="emailId"
                :preselected-value="email"
                :description="emailText"
                :placeholder="emailText"
                :margin-bottom="true"
                :validation-name="emailErrorsName"
                :type="'email'"
                :icon="false"
                :autofocus="true"
                @enterpressed="submitForm"
                @fieldvaluechanged="setEmail" />
      <sk-input :id="passwordId"
                :preselected-value="password"
                :description="passwordText"
                :placeholder="passwordText"
                :margin-bottom="true"
                :validation-name="passwordErrorsName"
                :type="'password'"
                :icon="false"
                @enterpressed="submitForm"
                @fieldvaluechanged="setPassword" />
      <button class="sk-btn sk-btn--default">{{ submitText }}</button>
      <div class="shared-form__remember-forgot-cont">
        <sk-checkbox :preselected-value="rememberMe"
                     :item-text="rememberText"
                     class="shared-form__remember-checkbox"
                     @checkboxvaluechanged="setRememberChckbox" />
        <router-link :to="forgotLink"
                     class="sk-link shared-form__forgot-link">{{ $gettext('Forgot your password?') }}</router-link>
      </div>
      <hr class="nav-login__divider"
          :data-content="orLoginText" />
      <button class="nav-btn"
              @click.prevent.stop="navigateToSSOLogin">
        <span class="nav-btn__text">{{ navButtonText }}</span>
      </button>
    </form>
    <hr-imitation :hr-text="hrText" />
    <social-links />
  </div>
</template>)

<script>
  import HrImitation from '@/components/shared_components/HrImitation';
  import constants from '@/modules/constants';
  import helpers from '@/helpers';

  export default {
    components: {
      'hr-imitation': HrImitation
    },
    data() {
      return {
        email: '',
        emailErrors: [],
        emailErrorsName: 'emailErrors',
        emailId: 'emailId',
        password: '',
        passwordErrors: [],
        passwordErrorsName: 'passwordErrors',
        passwordId: 'passwordId',
        phoneCode: '+47',
        phoneNumber: '',
        phoneNumberErrorsName: 'phoneNumberErrors',
        phoneNumberErrors: [],
        rememberMe: true,
        progressActive: false,
        punchoutProgressActive: false,
      };
    },
    computed: {
      submitText() { return ''; },
      hrText() { return ''; },
      passwordText() { return this.$gettext('Password'); },
      emailText() { return this.$gettext('Email'); },
      phoneCodeText() { return this.$gettext('Phone code'); },
      phoneNumberText() { return this.$gettext('Phone number'); },
      rememberText() { return this.$gettext('Remember me'); },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },
      forgotLink() { return this.$makeRoute({name: 'RestorePassword'}); },
      addJobAfterSignIn() { return this.$cookieManager.getCookie('addJobAfterSignIn') ? JSON.parse(this.$cookieManager.getCookie('addJobAfterSignIn')) : ''; },
      isPhoneOption() { return this.$route.query.option === 'phone'; },
      navButtonText() { return this.$gettext('NAV Employee Login'); },
      orLoginText() { return this.$gettext('or login with'); },
      isNavUser() { return this.email.endsWith('@nav.no'); }
    },
    watch: {
      clientWidth() {
        if (this.$isOnDomain('tikktalk')
          && this.clientWidth
          && this.clientWidth < 768
          && !this.$store.state.mobileApp) {
          window.location.href = `https://my.salita.no/${window.location.pathname.slice(1)}${window.location.search}`;
        }
      }
    },
    methods: {
      setEmail(value) { this.email = value; },
      setPassword(value) { this.password = value; },
      setPhoneCode(value) { this.phoneCode = value; },
      setPhoneNumber(value) { this.phoneNumber = value; },
      setRememberChckbox(value) { this.rememberMe = value; },
      validateEmail(email) {
        return helpers.validation.validateEmail(email);
      },
      navigateToSSOLogin() {
        window.location.assign(constants.SAML_PATH);
      }
    },
    beforeMount() {
      const emailField = document.getElementById(this.emailId);
      const passwordField = document.getElementById(this.passwordId);
      if (emailField && emailField.value) {
        this.email = emailField.value;
      }
      if (passwordField && passwordField.value) {
        this.password = passwordField.value;
      }
    }
  };
</script>

<style scoped>
  .shared-form {
    display: block;
    width: 100%;
    padding: 30px 0 55px;
  }

  .shared-form__remember-forgot-cont {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .shared-form__remember-checkbox {
    width: 50%;
  }

  .shared-form__forgot-link {
    display: inline-block;
    width: 50%;
    font-weight: normal;
    font-size: 12px;
    text-align: right;
  }

  .shared-form__inputs-wrapper {
    display: flex;
  }

  .shared-form__code-input {
    flex-shrink: 0;
    width: 90px;
    margin-right: 10px;
  }

  .shared-form__number-input {
    display: inline-block;
    flex-grow: 1;
    width: auto;
  }

  .shared-form-punchout-login__progress {
    margin-top: 200px;
    color: #000;
    text-align: center;
  }
</style>
