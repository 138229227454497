<script>
  import SharedForm from '@/components/auth_components/AuthSharedForm';

  export default {
    extends: SharedForm,
    data() {
      return {
        redirectTimer: '',
        oldPlatfLink: ''
      };
    },
    computed: {
      submitText() { return this.$gettext('Sign up'); },
      hrText() { return this.$gettext('or sign up with'); }
    },
    methods: {
      formIsValid() {
        let isValidForm = true;
        this.emailErrors = [];
        this.passwordErrors = [];

        if (!this.email) {
          this.emailErrors.push(this.$gettext('Please enter an email'));
          isValidForm = false;
        } else if (!this.validateEmail(this.email)) {
          this.emailErrors.push(this.$gettext('Email is not valid'));
          isValidForm = false;
        }
        if (!this.password) {
          this.passwordErrors.push(this.$gettext('Please enter a password'));
          isValidForm = false;
        } else if (this.password.length < 8) {
          this.passwordErrors.push(this.$gettext('Password is too short (minimum is 8 characters)'));
          isValidForm = false;
        }

        this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
        this.$store.commit('ErrorsStore/setError', {name: this.passwordErrorsName, errors: this.passwordErrors});

        return isValidForm;
      },
      submitForm() {
        if (this.formIsValid()) {
          const form = new FormData();
          form.append('identity[email]', this.email.toLowerCase());
          form.append('identity[password]', this.password.trim());
          this.progressActive = true;
          this.$store.dispatch('TTAuthStore/authorize', {form: form, type: 'signUp', rememberMe: this.rememberMe}).then((response) => {
            this.$store.commit('UserInfoStore/setUserInfo', {userInfoObj: {uid: response.identity.uid}});
            if (response.email_whitelisted) {
              this.$store.dispatch('TTWizardStore/setRole', 'employee').then(() => {
                this.getUserInfo(response);
              }).catch(() => {
                this.progressActive = false;
              });
            } else {
              this.getUserInfo(response);
            }
          }).catch((error) => {
            this.progressActive = false;
            if (error.error == 'Old platform user') {
              this.oldPlatfLink = error.old_platform.link;
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.getOldPlatformMessage(this.oldPlatfLink),
                method: 'modalCallback',
                context: this,
                btnText: this.$gettext('Go!')
              });
              this.redirectTimer = setTimeout(() => {
                window.location.href = this.oldPlatfLink;
              }, 5000);
            } else if (error && error.errors && error.errors.account === 'suppressed') {
              const template = this.$gettext('Please contact our support team to activate your account.<br> Please send an email to <a class="sk-link sk-link--default" href="mailto:%{email}">%{email}</a> for assistance');
              const messageText = this.$gettextInterpolate(template, {email: this.$getString('domainData', 'supportEmail')});
              this.$store.commit('InfoModalStore/setInfoModal', {text: messageText});
            } else {
              if (error.errors
                && error.errors.email
                && error.errors.email.length) {
                if (error.errors.email.includes('has already been taken') || error.errors.email.includes('er allerede i bruk')) {
                  this.emailErrors.push(this.$gettext('An account already exists for this email address. Please use the forgot password form to reset your password if you have forgotten it.'));
                } else if (error.errors.email.includes('Invalid Email Address')) {
                  this.emailErrors.push(this.$gettext('Please check the email address you have provided for spelling errors, and try again.'));
                }
              }
            }
            this.$store.commit('ErrorsStore/setError', {name: this.emailErrorsName, errors: this.emailErrors});
          });
        }
      },
      getUserInfo(response) {
        this.$fetcher.setLocale(this.$cookieManager.getCookie('locale') || this.$cookieManager.getCookie('serverLocale'), true).then(() => {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', response.identity.uid).then(() => {
            this.$store.commit('TTAuthStore/setAuthInfoToStorage', {token: response.token, uid: response.identity.uid});
            this.progressActive = false;
            // TODO: leads to an error, if there is a need to use research must be done
            // if (process
            //   && process.env
            //   && process.env.NODE_ENV == 'production') {
            //   this.$analytics.fbq.event('CompleteRegistration');
            // }
            window.location.reload();
          });
        });
      },
      getOldPlatformMessage(link) {
        const modalParagraphs = [
          this.$gettext('<b>Salita and TikkTalk have merged</b>, and will work together in the interpreting industry.'),
          this.$gettext('You have an account at %{link}, and we are not ready to migrate your account yet.'),
          this.$gettext('You will be transferred to %{link} in 5 seconds.')
        ];
        const paragraphs = modalParagraphs.map((paragraph) => `<p class="old-platform-message">${paragraph}</p>`);
        const template = paragraphs.join('');
        return this.$gettextInterpolate(template, {link: link.replace(/http:\/\/|https:\/\//gi, '')});
      },
      modalCallback() {
        clearInterval(this.redirectTimer);
        window.location.href = this.oldPlatfLink;
      }
    }
  };
</script>

<style>
  .sk-info-modal__text .old-platform-message {
    margin-bottom: 20px;
    text-align: left;
  }
</style>
