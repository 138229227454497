(<template>
  <div class="hr-imitation">
    <span :class="{'hr-imitation__text--grey-bg': greyBg}"
          class="hr-imitation__text">{{ hrText }}</span>
  </div>
</template>)

<script>
  export default {
    props: {
      hrText: {
        type: String,
        default: ''
      },
      greyBg: Boolean
    }
  };
</script>

<style scoped>
  .hr-imitation {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    margin: 20px 0;
    border: 1px solid #979797;
  }

  .hr-imitation__text {
    position: absolute;
    top: -9px;
    left: 50%;
    padding: 0 10px;
    background-color: #fff;
    color: #333;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    transform: translateX(-50%);
  }

  .hr-imitation__text--grey-bg {
    background-color: #e9ebef;
  }
</style>
