(<template>
  <div class="sign-up">
    <auth-tabs />
    <div class="sign-up__content-wrapper">
      <sign-up-form />
    </div>
  </div>
</template>)

<script>
  import AuthTabs from '@/components/auth_components/AuthTabs';
  import AuthSignUpForm from '@/components/auth_components/AuthSignUpForm';

  export default {
    components: {
      'auth-tabs': AuthTabs,
      'sign-up-form': AuthSignUpForm
    }
  };
</script>

<style scoped>
  .sign-up {
    display: block;
    width: 100%;
  }

  .sign-up__content-wrapper {
    display: block;
    width: 100%;
    max-width: 270px;
    margin: 0 auto;
  }
</style>
