(<template>
  <div class="auth-tabs">
    <nav class="auth-tabs__nav">
      <router-link v-for="(link, index) in links"
                   :key="index"
                   :to="link.path"
                   class="auth-tabs__nav-link">{{ link.name }}
      </router-link>
    </nav>
  </div>
</template>)

<script>
  export default {
    props: {
      links: {
        type: Array,
        default: () => []
      }
    }
  };
</script>

<style scoped>
  .auth-tabs {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    min-height: 60px;
    border-bottom: 1px solid #e9eaef;
  }

  .auth-tabs__nav {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding-bottom: 1px;
  }

  .auth-tabs__nav-link {
    display: inline-block;
    padding: 0 10px;
    border-bottom: 2px solid transparent;
    font-weight: bold;
    text-align: center;
  }

  .auth-tabs__nav-link.router-link-exact-active {
    border-bottom: 2px solid #ff5b24;
    border-bottom: 2px solid var(--color-secondary);
  }
</style>
